import { ICountry } from "@/types/country";
import { countryApi } from ".";

type ICountriesResponse = ServerResponse<ICountry[]>;

export const extendedApi = countryApi.injectEndpoints({
  endpoints: (build) => ({
    getCountries: build.query<ICountriesResponse, void>({
      query: () => ({
        url: ``,
      }),
    }),
  }),
});

export const { useGetCountriesQuery, useLazyGetCountriesQuery } = extendedApi;
