import React, { FC } from "react";
import { Box, Avatar, FormHelperText, Grid,FormControl, Typography, Divider, IconButton, Radio, TextField,Button, InputAdornment, Checkbox } from "@material-ui/core";
import { Form } from "@/_lib/formik/components/Form";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import { withStyles } from '@material-ui/core/styles';
import AddIcon from '@mui/icons-material/Add';
import { FormLabel } from "@/_lib/mui/forms/FormLabel";
import { colors } from "@/theme/colors";
// import { TextField } from "@/_lib/formik/components/TextField";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useBillingStyles } from "../../styles/BillingStyles";
import Dialog from '@mui/material/Dialog';
import AddBank from "../AddBank/AddBankModal";
import SearchIcon from '@mui/icons-material/Search';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';


const StyledTableRow = withStyles(() => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: colors.grey[200],
      },
    },
  }))(TableRow);


const data = [
    {
        Participant: "Uzodima Chinonzo",
        email: "uzodima@leads.com",
        InvoiceNumber: "INV-3066",
        status: "Pending",
        Amount: "$1,628,387.58",
        Date: "Feb 22, 2022, 1:00pm",
    },
    {
        Participant: "Uzodima Chinonzo",
        email: "uzodima@leads.com",
        InvoiceNumber: "INV-3066",
        status: "Pending",
        Amount: "$1,628,387.58",
        Date: "Feb 22, 2022, 1:00pm",
    },
    {
        Participant: "Uzodima Chinonzo",
        email: "uzodima@leads.com",
        InvoiceNumber: "INV-3066",
        status: "Pending",
        Amount: "$1,628,387.58",
        Date: "Feb 22, 2022, 1:00pm",
    },
]

const SettingBilling: FC = () => {
    const classes = useBillingStyles();
    
    const [status, setStatus] = React.useState('View all');

    const [open, setOpen] = React.useState(false);
    const openBankDetails = () => setOpen(true);
    const closeBankDetails = () => setOpen(false);

    const handleStatus = () => {
        setStatus(status);
      };

    return (
        <>
            <Box className={classes.billingBody}>
            <Box>
                <Typography variant="h4">Payment method</Typography>
                <Typography variant="body2" gutterBottom>Update your billing details and address.</Typography>
            </Box>
            <Divider/>
            <Box className={classes.form}>
            <Grid container xs={12} >
                <Grid item xs={4}>
                    <Typography variant="h4">Contact email</Typography>
                    <Typography variant="body2" gutterBottom>Where should invoices be sent?</Typography>
                </Grid>
                <Grid item xs={8}>
                    {/* <Form> */}
                    <Box>
                        <Radio
                        value="account email"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "A" }}
                        size="small"
                        color="primary"
                        />
                        <FormControl >
                            <Box>
                                <FormLabel>Send to my account email</FormLabel>
                                <Typography variant="body2" gutterBottom>uche@leads.com </Typography>
                            </Box>
                        </FormControl>
                    </Box>
                    <Box>
                    <Radio
                        value="alternative email"
                        name="radio-buttons"
                        inputProps={{ "aria-label": "A" }}
                        size="small"
                        color="primary"
                        />
                        <FormControl >
                            <Box>
                            <FormLabel>Send to an alternative email</FormLabel>
                            <Typography gutterBottom>
                                <TextField
                                    size="small"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                            <MailOutlineIcon /> 
                                            </InputAdornment>
                                        ),
                                    }}
                                    placeholder={"billing@lead.com"}
                                    className={classes.textField}
                                />
                            </Typography>
                            </Box>
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>
            <Divider />
            <Grid container xs={12}  className={classes.bankDetailsContainer}>
                <Grid item xs={4}>
                    <Typography variant="h4">Bank details</Typography>
                    <Typography variant="body2" gutterBottom>Select default payment method.</Typography>
                </Grid>
                <Grid item xs={6}>
                
                    <Box className={classes.activeBankDetails}>
                        <Grid container className={classes.bankDetailContent}>
                            <Grid item>
                            <Typography 
                            variant="h6" 
                            className={classes.activeBankName}
                            >
                            First City Monument Bank
                            </Typography>
                            <Typography 
                            variant="body2" 
                            gutterBottom
                            className={classes.activeBankNumber}
                            >
                            0984743392
                            </Typography>
                            <Button variant="text"  size="small" className={classes.textBtn}>
                                <Typography 
                                variant="body2" 
                                gutterBottom
                                className={classes.bankDefault}
                                >
                                Set as default
                                </Typography>
                            </Button>
                            <Button variant="text" size="small" >
                                <Typography 
                                variant="body2" 
                                gutterBottom
                                className={classes.bankEdit}
                                >
                                Edit
                                </Typography>
                            </Button>          
                            </Grid>
                            <Grid item><CheckCircleRoundedIcon fontSize="small" className={classes.checkbox}/></Grid>

                        </Grid>
                                          
                    </Box>
                    <br/>
                    <Box className={classes.bankDetails}>
                        <Grid container className={classes.bankDetailContent}>
                            <Grid item>
                                <Typography variant="h6">Standard Chartered</Typography>
                                <Typography 
                                variant="body2" 
                                gutterBottom
                                >
                                7492402332
                                </Typography>
                                <Button variant="text" className={classes.textBtn} size="small" >
                                    <Typography 
                                    variant="body2" 
                                    gutterBottom 
                                    >
                                    Set as default
                                    </Typography>
                                </Button>
                                <Button variant="text"size="small" >
                                    <Typography 
                                    variant="body2" 
                                    gutterBottom
                                    className={classes.bankEdit}
                                    >
                                    Edit
                                    </Typography>
                                </Button> 
                            </Grid>
                            <Grid item><RadioButtonUncheckedRoundedIcon fontSize="small"/></Grid>
                        </Grid>
                        
                    </Box>
                    <Box>
                        <Button variant="text" onClick={openBankDetails}>
                            <IconButton>
                            <AddIcon/>
                            </IconButton>
                            <Typography variant="body2">Add new banking method</Typography>
                        </Button>
                    </Box>
                    <Dialog
                    open={open}
                    onClose={closeBankDetails}
                    aria-labelledby="customized-dialog-title"
                    >
                        <AddBank/>
                    </Dialog>
                </Grid>
            </Grid>
            </Box>
            {/* </Form> */}
            <Box>
            </Box>
            </Box>
            <TableContainer component={Paper} className={classes.table}>
                <Grid container className={classes.tableIcons}>
                    <Grid item>
                        <Typography variant="h4">All Invoices</Typography>
                    </Grid>
                    <Grid item >
                        <Grid container className={classes.tableHead}> 
                        <Grid item className={classes.tableHeadItems}>
                        <Button variant="outlined" className={classes.tableBtn}>Download All</Button>
                        </Grid>
                        <Grid item className={classes.tableHeadItems}>
                        <TextField 
                        placeholder="Search"
                        size="small"
                        InputProps={{
                            startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                            ),
                        }}
                       />
                        </Grid>
                        <Grid item className={classes.tableHeadItems}>
                        <ToggleButtonGroup
                        value={status}
                        exclusive
                        onChange={handleStatus}
                        aria-label="Invoice Status"
                        size="small"
                        >
                        <ToggleButton value="View all" aria-label="View all" className={classes.toggleButton}>
                        View all
                        </ToggleButton>
                        <ToggleButton value="Paid" aria-label="Paid" className={classes.toggleButton}>
                        Paid
                        </ToggleButton>
                        <ToggleButton value="Pending" aria-label="Pending" className={classes.toggleButton}>
                        Pending
                        </ToggleButton>
                        <ToggleButton value="Cancelled" aria-label="Cancelled" className={classes.toggleButton}>
                        Cancelled
                        </ToggleButton>
                        </ToggleButtonGroup>
                        </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Divider/>
                <Table >
                    <TableHead>
                    <TableRow>
                        <TableCell>Participant</TableCell>
                        <TableCell>Invoice Number</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell></TableCell>

                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {data.map((data) => (
                        <StyledTableRow key={data.InvoiceNumber}>
                        <TableCell className={classes.avatarCell}>
                            <Box><Avatar>Ao</Avatar></Box>
                        <Box>
                        {data.Participant}
                            <FormHelperText>{data.email}</FormHelperText>
                        </Box>
                        </TableCell>
                        <TableCell>{data.InvoiceNumber}</TableCell>
                        <TableCell>
                        <Button variant="outlined" size="small"
                        className={data.status === "Pending" ? classes.pending : data.status==="Cancelled" ? classes.cancelled : classes.paid }
                        >
                            <AutorenewIcon fontSize="small"/>
                         {data.status}
                        </Button>
                            
                        </TableCell>
                        <TableCell>{data.Amount}</TableCell>
                        <TableCell>{data.Date}</TableCell>
                        <TableCell>
                            <Button variant="text">Download</Button>
                        </TableCell>
                        </StyledTableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            
        </>
    )
}

export default SettingBilling;