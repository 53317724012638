import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '@api/axiosBaseQuery';
import config from '@/config/config';

export const contactApi = createApi({
    baseQuery: axiosBaseQuery({ baseUrl: `${config.API_URL}/api/v1/contact`,  }),
    endpoints: () => ({}),
    reducerPath: 'contact',
    keepUnusedDataFor: 30,
    tagTypes: ['CONTACT', 'CONTACT_LIST'],
});
