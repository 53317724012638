import React from 'react';
import { useField, useFormikContext } from 'formik';

import { ISingleSelectProps, SingleSelect } from '@_lib/mui/forms/SingleSelect';
import cn from "classnames";
import { makeStyles } from "@material-ui/core/styles";


export const useFieldStyles = makeStyles((theme) => ({
    root: {
      "& .MuiInputBase-root": {
        borderRadius: "8px",
        height: "44px",
        background: "#fff",
        marginBottom: "15px"
        // width: "68%"
      },
      "& .MuiOutlinedInput-colorSecondary.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #D0D5DD",
        // boxShadow: "0px 1px 2px rgb(16 24 40 / 5%)",
    }
    },
  }));

export interface ISelectProps extends ISingleSelectProps {
    name: string;
    noHelperText?: boolean;
}

export const Select: React.FC<ISelectProps> = ({
    name,
    value,
    onChange,
    onBlur,
    menuTitle,
    data,
    label,
    fullWidth,
    disabled,
    error = false,
    placeholder,
    helperText,
    noHelperText = false,
    ...other
}) => {
    const [field, meta] = useField(name);
    const { isSubmitting } = useFormikContext();

    const classes = useFieldStyles();

    const isError = meta && !!(meta.touched && meta.error);
    return (
      <SingleSelect
        name={name ? name : field?.name}
        className={cn(classes.root, other.className)}
        value={value || value === "" ? value : field?.value}
        onChange={onChange ? onChange : field?.onChange}
        onBlur={onBlur ? onBlur : field?.onBlur}
        label={label}
        placeholder={placeholder}
        menuTitle={menuTitle}
        data={data}
        fullWidth={fullWidth}
        disabled={disabled ? disabled : isSubmitting}
        error={error ? error : isError}
        helperText={
          noHelperText ? undefined : isError ? meta?.error : helperText ?? " "
        }
        {...other}
      />
    );
};
