import { FC, useEffect } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { useDidMount } from "@/_shared/hooks/useDidMount";
import { DEFAULT_ERROR_MESSAGE } from "@_shared/constants";
import { axiosServer } from "./axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export interface IResponseBody {
  data: any;
  message: string;
  success: boolean;
  errors?: any;
}

export const HandleResponseErrors: FC = () => {
  const notify = (message: string) =>
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });

  let responseInterceptor: number;
  useDidMount(() => {
    responseInterceptor = axiosServer.interceptors.response.use(
      (res: AxiosResponse) => res,

      (err: AxiosError) => {
        try {
          const status = err?.response?.status;

          if (
            err?.response?.status === 403 ||
            err?.response?.data === "unauthenticated"
          ) {
            localStorage.removeItem("authState");
            const { pathname } = window.location;
            window.location.href = `/auth/login?redirect=${pathname}`;
          }

          const errors = (err?.response?.data as any).errors;

          if (errors) {
            Object.values(errors).map((error: any) => {
              return error.map((err: string) => notify(err));
            });
          } else if ((err?.response?.data as any).message) {
            notify((err?.response?.data as any).message);
          } else {
            notify(DEFAULT_ERROR_MESSAGE);
          }
        } catch (error) {}
      }
    );
  });

  useEffect(() => () => {
    if (responseInterceptor) {
      axiosServer.interceptors.response.eject(responseInterceptor);
    }
  });
  return <ToastContainer />;
};
