import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IAuctionActivities } from "@/types/auction";

interface InitialState {
  auctionActivities: IAuctionActivities | null;
}

const initialState: InitialState = {
  auctionActivities: null,
};

export const auctionActivitiesSlice = createSlice({
  name: "auctionActivitiesSlice",
  initialState,
  reducers: {
    setAuctionActivities: (state, action: PayloadAction<InitialState>) => {
      state.auctionActivities = action.payload.auctionActivities;
    },
    removeAuctionActivities: (state) => {
      state.auctionActivities = null;
    },
  },
});

export const { setAuctionActivities, removeAuctionActivities } =
  auctionActivitiesSlice.actions;
export default auctionActivitiesSlice.reducer;
