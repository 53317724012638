import { BaseQueryFn } from "@reduxjs/toolkit/query";
import { AxiosRequestConfig, AxiosError } from "axios";
import { axiosServer } from "./axios";
import config from "../config/config";
import encryptPayload from "@/_shared/utils/encryptPayload";

// todo: fix eslint warnings
export const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: "" }
  ): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig["method"];
      data?: AxiosRequestConfig["data"];
      params?: AxiosRequestConfig["params"];
      headers?: AxiosRequestConfig["headers"];
    },
    unknown,
    unknown
  > =>
  async ({ url, method = "GET", data, params, headers }) => {
    try {
      const storage = localStorage?.getItem("authState");

      const { token } = !!storage ? JSON.parse(storage) : "";

      const exlcudePathsFromEncryption = (url: string) => {
        return method === "POST" || method === "PUT" || method === "PATCH"
          ? url
          : null;
      };

      let shouldEncrypt;

      const excludeUrl = [
        exlcudePathsFromEncryption(`${config.API_URL}/api/v1/auction`),
        exlcudePathsFromEncryption(`${config.API_URL}/api/v1/asset`),
        exlcudePathsFromEncryption(`${config.API_URL}/api/v1/contact${url}`),
        exlcudePathsFromEncryption(`${config.API_URL}/api/v1/contact`),
        exlcudePathsFromEncryption(
          `${config.API_URL}/api/v1/auctionee/invite/self-registration`
        ),
        exlcudePathsFromEncryption(
          `${config.API_URL}/api/v1/auctionee/invite/resend/all`
        ),
        exlcudePathsFromEncryption(
          `${config.API_URL}/api/v1/auctionee/invite/accept`
        ),
        exlcudePathsFromEncryption(
          `${config.API_URL}/api/v1/auctionee/invite/status`
        ),
        exlcudePathsFromEncryption(
          `${config.API_URL}/api/v1/auth/reset-password`
        ),
        exlcudePathsFromEncryption(
          `${config.API_URL}/api/v1/auth/reset-password/verify`
        ),
        exlcudePathsFromEncryption(
          `${config.API_URL}/api/v1/auth/reset-password/confirm`
        ),
      ];

      if (excludeUrl.includes(baseUrl + url)) {
        shouldEncrypt = false;
      }

      const result = await axiosServer({
        url: baseUrl + url,
        method,
        // data,
        data:
          shouldEncrypt === false ? data : encryptPayload(JSON.stringify(data)),
        params,
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      });

      return result;
    } catch (axiosError) {
      const err = axiosError as AxiosError;

      if (err?.response?.status === 401) {
        localStorage.removeItem("authState");

        const { pathname } = window.location;
        window.location.href = `/auth/login?redirect=${pathname}`;
      }

      if (err?.response?.status === 403) {
        localStorage.removeItem("authState");

        const { pathname } = window.location;
        window.location.href = `/auth/login?redirect=${pathname}`;
      }
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data,
        },
      };
    }
  };
