import { FormLabel as MuiFormLabel } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { fontSizes } from '@theme/fonts';
import { colors } from '@theme/colors';

export const FormLabel = withStyles((theme) => ({
    root: {
        fontSize: fontSizes.small[20],
        lineHeight: '27px',
        marginBottom: theme.spacing(1),
        color: colors.grey[700],
        fontWeight: 500
    },
}))(MuiFormLabel);
