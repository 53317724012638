import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import SwitchAccountModal from "../../Modal/SwitchAccountModal";
import DesktopDrawer from "./Drawer/AuctioneeDrawer/DesktopDrawer";
import DrawerContent from "./Drawer/AuctioneeDrawer/DrawerContent";
import MobileDrawer from "./Drawer/AuctioneeDrawer/MobileDrawer";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import AUCTIONEE_PATHS from "@/router/paths/auctionee";
import LogoutModal from "../../Modal/LogoutModal";
import { useAuthUserDetail } from "@/_shared/hooks/useAuthUserDetail";

const AuctioneerSideMenu = () => {
  const navigate = useNavigate();

  const [isSwitchAccountModalOpen, setIsSwitchAccountModalOpen] =
    useState(false);
    const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  const [desktopOpen, setIsDesktopOpen] = useState(false);
  const [mobileOpen, setIsMobileOpen] = useState(false);

  const authUser = useAuthUserDetail();
  const { getUserDetails } = authUser;
  const { user } = getUserDetails();

  const existingAuctioneeUserId = localStorage.getItem(
    "existingAuctioneeUserId"
  );
  const auctionId = localStorage.getItem("auctionId");

  const toggleDrawer = () => {
    setIsDesktopOpen(!desktopOpen);
  };

  const handleSwitchAccount = () => {
    setIsSwitchAccountModalOpen(true);
  };

  const handleShowLogOut = () => {
    setIsLogoutModalOpen(true);
  };

  /*
    This logic is used to redirect new Auctionee to the auction
    they were invited to after successful registration.
  */
  const newAuctioneeEmail = localStorage.getItem("newAuctioneeEmail");
  useEffect(() => {
    if (newAuctioneeEmail === user?.email) {
      navigate(generatePath(AUCTIONEE_PATHS.VIEW_AUCTION, { id: auctionId }));
      localStorage.removeItem("newAuctioneeEmail");
      localStorage.removeItem("auctionId");
    }
  }, [auctionId, newAuctioneeEmail]);

  /*
    This logic is used to redirect existing Auctionee to the auction
    they were invited to.
  */
  useEffect(() => {
    if (existingAuctioneeUserId === user?.id) {
      navigate(generatePath(AUCTIONEE_PATHS.VIEW_AUCTION, { id: auctionId }));
      localStorage.removeItem("existingAuctioneeUserId");
      localStorage.removeItem("auctionId");
    }
  }, [existingAuctioneeUserId]);

  return (
    <Box>
      <DesktopDrawer isOpen={desktopOpen} handleDrawerClose={toggleDrawer}>
        <DrawerContent
          isDesktopOpen={desktopOpen}
          handleDrawerOpen={toggleDrawer}
          handleShowLogOut={handleShowLogOut}
          handleSwitchAccount={handleSwitchAccount}
        />
      </DesktopDrawer>

      <MobileDrawer
        mobileOpen={mobileOpen}
        onMobileOpen={() => setIsMobileOpen(!mobileOpen)}
      >
        <DrawerContent
          isMobileOpen={mobileOpen}
          handleDrawerOpen={toggleDrawer}
          handleShowLogOut={handleShowLogOut}
          handleSwitchAccount={handleSwitchAccount}
        />
      </MobileDrawer>
      <SwitchAccountModal
        isModalOpen={isSwitchAccountModalOpen}
        onClose={() => setIsSwitchAccountModalOpen(false)}
        authUser={authUser}
      />
      <LogoutModal
        isModalOpen={isLogoutModalOpen}
        onClose={() => setIsLogoutModalOpen(false)}
      />
    </Box>
  );
};

export default AuctioneerSideMenu;
