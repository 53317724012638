import { Yup } from '@_lib/yup';
import { AnySchema } from 'yup';

export const addBankValidationSchema = (): AnySchema => {
    return Yup.object({
        bankName: Yup.string()
            .trim()
            .required('Bank is required'),
        accountNumber: Yup.string()
        .trim()
        .required('Account number is required')
        .max(256, 'Too many symbols'),
        accountName: Yup.string()
        .trim()
        .required('Account name is required')
        .max(256, 'Too many symbols'),
    });
};

