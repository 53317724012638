import { AxiosError, AxiosResponse } from 'axios';
import { IErrorContent, IErrorResponse } from '_shared/types';
import { FormikErrors } from 'formik/dist/types';

export interface ITransformedErrorContent {
    [key: string]: string | string[] | this;
}

const bringValuesToString = (value: string | string[]): string =>
    Array.isArray(value) ? value.join(', \n\r') : value;

export const transformErrorContent = (
    errorContent: IErrorContent
): ITransformedErrorContent => {
    if (
        errorContent &&
        typeof errorContent === 'object' &&
        !Array.isArray(errorContent)
    ) {
        const updatedErrorContent: ITransformedErrorContent = {};

        for (const field in errorContent) {
            if (Object.prototype.hasOwnProperty.call(errorContent, field)) {
                if (field.includes('.')) {
                    const keys = field.split('.');
                    let currentNode = updatedErrorContent;

                    keys.forEach((currentKey, index) => {
                        if (typeof currentNode[currentKey] === 'undefined') {
                            if (index === keys.length - 1) {
                                currentNode[currentKey] = bringValuesToString(
                                    errorContent[field]
                                );
                            } else {
                                currentNode[currentKey] = {};
                            }
                        }

                        if (typeof currentNode[currentKey] !== 'string') {
                            currentNode = currentNode[
                                currentKey
                            ] as ITransformedErrorContent;
                        }
                    });
                } else {
                    updatedErrorContent[field] = bringValuesToString(
                        errorContent[field]
                    );
                }
            }
        }

        return updatedErrorContent;
    } else {
        return {};
    }
};

// todo: delete this function when all thunks that submit forms will be deleted
export const handleBackendFormErrors = (
    error: AxiosError<IErrorResponse>,
    setErrors: (errors: FormikErrors<ITransformedErrorContent>) => void
): void => {
    if (error?.response?.status === 400) {
        const {
            response: {
                data: { errorContent },
            },
        } = error;

        if (errorContent) {
            const errors = transformErrorContent(errorContent);
            // TODO: We should discuss it. Could we use setState instead setError?
            setErrors(errors as FormikErrors<ITransformedErrorContent>);
        } else {
            alert(error);
        }
    }
};

export const handleRTKQFormErrors = (
    error: AxiosResponse<IErrorResponse>,
    setErrors: (errors: FormikErrors<ITransformedErrorContent>) => void
): void => {
    if (error?.status === 400) {
        const {
            data: { errorContent },
        } = error;

        if (errorContent) {
            const errors = transformErrorContent(errorContent);
            // TODO: We should discuss it. Could we use setState instead setError?
            setErrors(errors as FormikErrors<ITransformedErrorContent>);
        } else {
            alert(error);
        }
    }
};
