import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import { global } from "./slice";

import * as Sentry from "@sentry/react";
import { auctioneerApi } from "@/api/baseQueries/auctioneer";
import { auctioneeApi } from "@/api/baseQueries/auctionee";
import { authApi } from "@/api/baseQueries/auth";
import { countryApi } from "@/api/baseQueries/country";

import { authorization } from "./auth/slice";
import { currencyApi } from "@/api/baseQueries/currency";
import { auctionApi } from "@/api/baseQueries/auction";
import { assetApi } from "@/api/baseQueries/asset";
import { auctions } from "./auction/slice";
import { contactApi } from "@/api/baseQueries/contact";
import { bidApi } from "@/api/baseQueries/bid";
import { bankApi } from "@/api/baseQueries/bank";
import utilSlice from "./utilSlice";
import auctionActivitiesSlice from "./auctionActivitiesSlice";

const rootReducer = combineReducers({
  utilSlice: utilSlice,
});

const persistedReducers = persistReducer(
  {
    key: "psApp",
    storage: storage,
  },
  rootReducer
);

export const store = configureStore({
  reducer: {
    global,
    authorization,
    auctions,
    persistedReducers,
    auctionActivitiesSlice: auctionActivitiesSlice,
    [auctioneerApi.reducerPath]: auctioneerApi.reducer,
    [auctioneeApi.reducerPath]: auctioneeApi.reducer,
    [auctionApi.reducerPath]: auctionApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [countryApi.reducerPath]: countryApi.reducer,
    [currencyApi.reducerPath]: currencyApi.reducer,
    [assetApi.reducerPath]: assetApi.reducer,
    [contactApi.reducerPath]: contactApi.reducer,
    [bidApi.reducerPath]: bidApi.reducer,
    [bankApi.reducerPath]: bankApi.reducer,
  },
  middleware: (gDM) =>
    gDM({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      auctioneerApi.middleware,
      auctioneeApi.middleware,
      auctionApi.middleware,
      authApi.middleware,
      countryApi.middleware,
      currencyApi.middleware,
      assetApi.middleware,
      contactApi.middleware,
      bidApi.middleware,
      bankApi.middleware,
    ]),
  enhancers: [Sentry.createReduxEnhancer()],
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const persistor = persistStore(store);

export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
