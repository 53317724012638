import { useCallback } from "react";
import { AUTH_PATHS } from "@/router/paths/auth";

function useLogOut(): { logOut: () => void } {
  const logOut = useCallback(async () => {
    localStorage.removeItem("authState");
    window.location.href = AUTH_PATHS.LOGIN;
  }, []);

  return { logOut };
}

export default useLogOut;
