import ILight from '@_shared/assets/fonts/IBMPlexSans-Light.ttf'
import IRegular from '@_shared/assets/fonts/IBMPlexSans-Regular.ttf'
import IMedium from '@_shared/assets/fonts/IBMPlexSans-Medium.ttf'
import ISemiBold from '@_shared/assets/fonts/IBMPlexSans-SemiBold.ttf'
import IBold from '@_shared/assets/fonts/IBMPlexSans-Bold.ttf'
import ILightItalic from '@_shared/assets/fonts/IBMPlexSans-Italic.ttf'
import IRegularItalic from '@_shared/assets/fonts/IBMPlexSans-LightItalic.ttf'
import IMediumItalic from '@_shared/assets/fonts/IBMPlexSans-MediumItalic.ttf'
import ISemiBoldItalic from '@_shared/assets/fonts/IBMPlexSans-SemiBoldItalic.ttf'
import IBoldItalic from '@_shared/assets/fonts/IBMPlexSans-BoldItalic.ttf'


export const fontSizes = {
  small: {
      5: '10px',
      10: '12px',
      20: '14px',
      30: '15px',
      40: '16px',
      50: '18px',
  },

  middle: {
      10: '20px',
      20: '24px',
      30: '30px',
      40: '36px',
  },

  large: {
      10: '40px',
      20: '48px',
      30: '60px',
      40: '70px',
  },
};

export const IBMLight = {
  fontFamily: 'IBM Plex Sans',
  fontStyle: 'normal',
  fontWeight: 300,
  src: `local('IBM'), url(${ILight}) format('truetype')`,
};

export const IBMRegular = {
  fontFamily: 'IBM Plex Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `local('IBM'), url(${IRegular}) format('truetype')`,
};

export const IBMMedium = {
  fontFamily: 'IBM Plex Sans',
  fontStyle: 'normal',
  fontWeight: 500,
  src: `local('IBM'), url(${IMedium}) format('truetype')`,
};

export const IBMSemiBold = {
  fontFamily: 'IBM Plex Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  src: `local('IBM'), url(${ISemiBold}) format('truetype')`,
};

export const IBMBold = {
  fontFamily: 'IBM Plex Sans',
  fontStyle: 'normal',
  fontWeight: 700,
  src: `local('IBM'), url(${IBold}) format('truetype')`,
};

export const IBMLightItalic = {
  fontFamily: 'IBM Plex Sans',
  fontStyle: 'italic',
  fontWeight: 300,
  src: `local('IBM'), url(${ILightItalic}) format('truetype')`,
};

export const IBMRegularItalic = {
  fontFamily: 'IBM Plex Sans',
  fontStyle: 'italic',
  fontWeight: 400,
  src: `local('IBM'), url(${IRegularItalic}) format('truetype')`,
};

export const IBMMediumItalic = {
  fontFamily: 'IBM Plex Sans',
  fontStyle: 'italic',
  fontWeight: 500,
  src: `local('IBM'), url(${IMediumItalic}) format('truetype')`,
};

export const IBMSemiBoldItalic = {
  fontFamily: 'IBM Plex Sans',
  fontStyle: 'italic',
  fontWeight: 600,
  src: `local('IBM'), url(${ISemiBoldItalic}) format('truetype')`,
};

export const IBMBoldItalic = {
  fontFamily: 'IBM Plex Sans',
  fontStyle: 'italic',
  fontWeight: 700,
  src: `local('IBM'), url(${IBoldItalic}) format('truetype')`,
};