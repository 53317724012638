import { Yup } from '@_lib/yup';
import { AnySchema } from 'yup';

export const passwordFormValidationSchema = (): AnySchema => {
    return Yup.object({
        currentPassword: Yup.string().required('Enter your current password'),
        newPassword: Yup.string().required('Enter your new password'),
        setNewPassword: Yup.string().required('Re-enter your new password')

    });
};

