import React, { FC } from "react";
import {
  Box,
  Grid,
  IconButton,
  Button,
  Typography,
  Stack,
  DialogContentText,
} from "@mui/material";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CloseIcon from "@mui/icons-material/Close";
import { Form } from "@/_lib/formik/components/Form";
import { FormLabel } from "@/_lib/mui/forms/FormLabel";
import { Formik } from "formik";
import { Select } from "@/_lib/formik/components/Select";
import { transformToSelectOptionList } from "@/_shared/utils/functions/transformToSelectOptionList";
import { SelectOptions } from "@_lib/mui/forms/SingleSelect";
import { TextField } from "@/_lib/formik/components/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { ReactComponent as HouseSvg } from "@_shared/assets/icons/house-icon.svg";
import { addBankValidationSchema } from "./addBankValidationSchema";
import { COMPANY_BANK_DETAILS } from "@/pages/auth/types";
import { banks } from "@/pages/auth/constants";

const AddBank: FC = () => {
  const validationSchema = addBankValidationSchema();

  const banksSelectpOtion: SelectOptions = transformToSelectOptionList(banks);

  const onSubmit = () => {};

  return (
    <>
      <Box>
        <DialogTitle>
          <Grid container sx={{ justifyContent: "space-between" }}>
            <Grid item>
              <HouseSvg />
            </Grid>
            <Grid item>
              <IconButton>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Typography variant="h6">Add bank Details</Typography>
          <Typography variant="body2">
            Never miss a payment or auction again with accurate and current
            information
          </Typography>
        </DialogContent>
        <Formik
          initialValues={COMPANY_BANK_DETAILS}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          <Form>
            <DialogContent>
              <Box>
                <FormLabel>Role</FormLabel>
                <Select
                  data={banksSelectpOtion}
                  type={"text"}
                  name={"bankName"}
                  menuTitle="Select your bank"
                  fullWidth
                />
              </Box>
              <Box>
                <FormLabel>Account Number</FormLabel>
                <TextField
                  size="small"
                  type={"text"}
                  id={"accountNumber"}
                  name={"accountNumber"}
                  placeholder={"Enter your account number"}
                  fullWidth
                />
              </Box>
              <Box>
                <FormLabel>Name</FormLabel>
                <TextField
                  size="small"
                  type={"text"}
                  id={"accountName"}
                  name={"accountName"}
                  placeholder={"Enter your account name"}
                  fullWidth
                  helperText={"Account name loading..."}
                />
              </Box>
            </DialogContent>

            <DialogActions>
              {/* <Box> */}
              {/* <Grid container sx={{justifyContent: "space-evenly"}}> */}
              {/* <Grid item > */}
              <Button variant="outlined" sx={{ textTransform: "none" }}>
                Cancel
              </Button>
              {/* </Grid> */}
              {/* <Grid item > */}
              <Button variant="contained" sx={{ textTransform: "none" }}>
                Add Bank
              </Button>
              {/* </Grid> */}
              {/* </Grid> */}
              {/* </Box> */}
            </DialogActions>
          </Form>
        </Formik>
      </Box>
    </>
  );
};
export default AddBank;
