import React from "react";
import cn from "classnames";
import { Form as FormikForm, FormikFormProps } from "formik";

import { FormErrorsHandler } from "@api/FormErrorsHandler";
import { useFormStyles } from "./styles";


export interface IFormProps extends FormikFormProps {
  className?: string;
  error?: unknown;
  nested?: boolean;
}

export const Form: React.FC<IFormProps> = ({
  className,
  children,
  error,
  nested,
  ...props
}) => {
  const classes = useFormStyles();
  if (nested) {
    return (
      <div className={cn(classes.root, className)}>
        <>
          {children}
          {error && <FormErrorsHandler error={error} />}
        </>
      </div>
    );
  }
  return (
    <FormikForm {...props} className={cn(classes.root, className)}>
      <>
        {children}
        {error && <FormErrorsHandler error={error} />}
      </>
    </FormikForm>
  );
};
