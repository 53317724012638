import { FC } from "react";
import { Box } from "@mui/material";
import AuctioneeSideMenu from "@/_shared/components/layout/SideMenu/AuctioneeSideMenu";

type AuctioneeLayoutProps = {
  children: any;
};

export const AuctioneeLayout: FC<AuctioneeLayoutProps> = ({ children }) => {
  return (
    <Box sx={{ display: "flex" }}>
      <AuctioneeSideMenu />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: "1rem",
          px: {
            xs: "16px",
            sm: "32px",
          },
          mt: {
            xs: "4.5rem",
            sm: "70px",
            md: "0",
          },
          // ml: {
          //   xs: "0",
          //   sm: "5rem",
          // },
          background: "#FCFCFD !important",
          height: "100vh !important",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
