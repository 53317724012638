import { IUser } from "@/types/auth";

interface UserDetailProps {
  isAuthenticated: boolean;
  token: string | null;
  user: IUser | null;
}

export interface AuthUserDetailProps {
  getUserDetails: () => UserDetailProps;
  getUserFullname: () => string;
  getUserInitials: () => string;
}

const getUserDetails = () => {
  const data = JSON.parse(localStorage.getItem("authState")!);
  return data as UserDetailProps;
};

const getUserFullname = () => {
  const userDetail = getUserDetails();
  return `${userDetail.user?.first_name} ${userDetail.user?.last_name}`;
};

const getUserInitials = () => {
  const userDetail = getUserDetails();
  return `${userDetail.user?.first_name} ${userDetail.user?.last_name}`
    .split(" ")
    .map((x) => x.charAt(0))
    .join("")
    .substr(0, 2)
    .toUpperCase();
};

export const useAuthUserDetail = () => {
  return {
    getUserDetails,
    getUserFullname,
    getUserInitials,
  };
};
