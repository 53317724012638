import React, { FC } from "react";
import { Formik } from "formik";
import { TextField } from "@/_lib/formik/components/TextField";
import { Form } from "@/_lib/formik/components/Form";
import { SETTINGS_PASSWORD_DETAILS } from "../../types";

import { Box, Grid, Typography, Divider, IconButton, Button, FormLabel, FormHelperText } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { passwordFormValidationSchema } from "./passwordFormValidationSchema";
import { usePasswordStyles } from "../../styles/passwordStyle";



const Password: FC = () => {
    const [showPassword, setShowPassword] = React.useState(false);

    const classes = usePasswordStyles();
    // const navigate = useNavigate();
    // const dispatch = useDispatch()

    const validationSchema = passwordFormValidationSchema();
    const handleClickShowPassword = () => setShowPassword((show) => !show);


    const onsubmit = () => {

    }
    

    return (
        <>
        <Grid container justifyContent={"space-between"} md={12} className={classes.body}>
            <Grid item >
                <Typography variant="h3" className={classes.header}>Password</Typography>
                <FormHelperText>Please enter your current password to change your password.</FormHelperText>
                {/* <Typography >Please enter your current password to change your password.</Typography> */}
            </Grid>
            <Grid item>
                <Button variant="contained" className={classes.button}>
                    Update Password
                </Button>
            </Grid>
        </Grid>
         <Formik 
         initialValues={SETTINGS_PASSWORD_DETAILS}
         onSubmit={onsubmit}
         validationSchema={validationSchema}

        >
            {({errors, isSubmitting}) => (
                <Form >
                <Divider variant="middle"/>
                <Grid container md={12} className={classes.body}>
                    <Grid item xs={4} >
                        <FormLabel>Current Password</FormLabel>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField 
                            size="small"
                            name={"currentPassword"}
                            id={"currentPassword"}
                            placeholder={"Enter current password"}
                            type={showPassword ? 'text' : 'password'}
                            fullWidth
                        />
                    </Grid>

                </Grid>
                <Divider variant="middle"/>
                <Grid container md={12} className={classes.body}>
                    <Grid item xs={4}>
                        <FormLabel>New Password</FormLabel>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField 
                            size="small"
                            name={"newPassword"}
                            id={"newPassword"}
                            placeholder={"Enter new password"}
                            type={showPassword ? 'text' : 'password'}
                            fullWidth
                        />
                    </Grid>

                </Grid>
                <Divider variant="middle"/>
                <Grid container md={12} className={classes.body}>
                    <Grid item xs={4}>
                        <FormLabel>Confirm new Password</FormLabel>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField 
                            size="small"
                            id={"confirmNewPassword"}
                            name={"confirmNewPassword"}
                            placeholder={"Confirm password"}
                            type={showPassword ? 'text' : 'password'}
                            fullWidth
                        />
                    </Grid>

                </Grid>
            </Form>
    )}
            
        </Formik>
        </>
    )
}
export default Password;