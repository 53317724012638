import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '@api/axiosBaseQuery';
import config from '@/config/config';

export const auctioneeApi = createApi({
    baseQuery: axiosBaseQuery({ baseUrl: `${config.API_URL}/api/v1/auctionee` }),
    endpoints: () => ({}),
    reducerPath: 'auctionee',
    keepUnusedDataFor: 60,
    tagTypes: [],
});
