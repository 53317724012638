import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '@api/axiosBaseQuery';
import config from '@/config/config';

export const auctionApi = createApi({
    baseQuery: axiosBaseQuery({ baseUrl: `${config.API_URL}/api/v1/auction` }),
    endpoints: () => ({}),
    reducerPath: 'auction',
    keepUnusedDataFor: 60,
    tagTypes: ['AUCTION', 'AUCTIONS', 'AUCTION_INVITES', 'AUCTION_RESULT'],
});
