import { makeStyles } from "@material-ui/core";
import { colors } from "@theme/colors";
import { fontSizes } from "@theme/fonts";

export const useSideMenuStyles = makeStyles((theme) => ({

  MuiList: {
    "&.MuiListItem": {

      "& a" : {
        textDecoration: "none !important",
      }

    },
    
  },
  
  listItemText: {
    flex: "1 1 auto",
    minWidth: 0,
    marginTop: "4px",
    marginBottom: "4px",
    fontWeight: 600,
    fontSize: fontSizes.small[20],
    color: `${colors.grey[700]} !important`,
    textDecoration: "none !important",
    opacity: 0
  },
  showText: {
    opacity: "1 !important"
  },
  listItemTextBottom: {
    marginBottom: "-4px",
    fontWeight: 600,
    color: `${colors.grey[700]} !important`,
    fontSize: "14px",
  },
  listItemTextSub: {
    // display: "block"
    fontSize: "12px",
  },
  listItemTextBtn: {
    "&:hover": {
      cursor: "auto",
      background: "none !important"
    },
    "&:visited": {
      cursor: "auto",
      background: "none !important"
    },
    "&:active": {
      cursor: "auto",
      background: "none !important"
    },
  },
  switchIcon: {
    zIndex: 500,
    cursor: "pointer",
    margin: "0px 12px -4px 0px",
    width: "15px"
  },
  LogoutIcon: {
    zIndex: 500,
    cursor: "pointer",
    margin: "0px 5px -4px 0px",
    width: "15px"
  },
  raydaLogoHolder: {
    position: "relative",
    top: "10px",
    left: "-65px"
  },
  raydaLogo : {
    position: "relative",
    top: "32px",
    width: "132px"
  },
  logo: {
    width: "55%",
    position: "relative",
    top: "10px",
    left: "20px",
    height: "100%"
  },
  userName: {
    marginRight: "30px"
  }
}));