import { CSSObject, Theme, styled } from "@mui/material/styles";
import React from "react";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import raydaLogo from "@_shared/assets/icons/bidda-logo.svg";
import { Box } from "@mui/material";
import raydaIcon from "@_shared/assets/icons/bidda-icon.svg";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useSideMenuStyles } from "../../styles";

const drawerWidth = 260;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  // width: `calc(${theme.spacing(7)} + 1px)`,
  width: 80,
  // [theme.breakpoints.up("sm")]: {
  //   width: `calc(${theme.spacing(8)} + 1px)`,
  // },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface Props {
  isOpen: boolean;
  handleDrawerClose: () => void;
}

const DesktopDrawer: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  handleDrawerClose,
  children,
}) => {
  const classes = useSideMenuStyles();

  return (
    <Drawer
      variant="permanent"
      open={isOpen}
      sx={{
        display: { xs: "none", sm: "none", md: "block" },
        padding: "0px 30px",
      }}
    >
      <DrawerHeader sx={{ display: "grid", placeContent: "center" }}>
        {isOpen ? (
          <Box
            sx={{ display: "flex", alignItems: "center", pt: "24px", mr: 3 }}
          >
            <IconButton onClick={handleDrawerClose} sx={{ mr: "5px" }}>
              <ChevronLeftIcon />
            </IconButton>
            <Box sx={{ mt: "2px" }}>
              <img src={raydaLogo} width={100} />
            </Box>
          </Box>
        ) : (
          <Box sx={{ mt: 3 }}>
            <img src={raydaIcon} />
          </Box>
        )}
      </DrawerHeader>

      {children}
    </Drawer>
  );
};

export default DesktopDrawer;
