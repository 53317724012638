import React, { useState, FC } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Grid,
  Typography,
  Divider,
  IconButton,
  Button,
  FormHelperText,
} from "@material-ui/core";
import Badge, { BadgeProps } from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import Password from "../forms/Password/PasswordForm";
import { colors } from "@/theme/colors";
import SideMenu from "@/_shared/components/layout/SideMenu/AuctioneerSideMenu";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import SettingBilling from "../forms/BillingAndPayment";
import CompanyProfile from "../forms/companyDetails/companyDetailsForm";
// import { useSettingStyles } from "../styles/settingsPageStyle";

const useHeaderStyles = makeStyles((theme) => ({
  body: {
    backgroundColor: colors.grey[25],
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "100px",
      paddingRight: "30px",
    },
  },
  head: {
    justifyContent: "space-between",
    padding: theme.spacing(3),
    display: "flex",
  },
  buttonContainer: {
    padding: "30px 15px 10px 15px",
  },
  buttongroup: {
    fontWeight: 600,
    textTransform: "none",
    color: colors.grey[600],
    borderColor: colors.grey[300],
    "&:hover": {
      backgroundColor: colors.grey[300],
      color: colors.grey[800],
      fontWeight: 800,
      borderColor: colors.grey[300],
    },
  },
}));

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 0,
    top: 18,
    border: `2px solid ${colors.error[500]}`,
    padding: "0 4px",
    backgroundColor: colors.error[500],
    color: colors.white,
  },
}));

const SettingsPage: FC = () => {
  const classes = useHeaderStyles();

  const [content, setContent] = useState("company profile");

  const handleContentChange = (event: React.MouseEvent, newContent: string) => {
    setContent(newContent);
  };

  return (
    <>
      {/* <Box sx={{ display: "flex" }}> 
                <SideMenu />
            </Box> */}
      <Box component="main" className={classes.body}>
        <Grid item className={classes.head} xs={12}>
          <Grid item>
            <Typography variant={"h2"}>Setting</Typography>
            <Typography variant="body1">
              Customize your Auction experience with our easy-to-use Settings
              feature.
            </Typography>
          </Grid>
          <Grid>
            <IconButton>
              <StyledBadge badgeContent={4}>
                <NotificationsNoneIcon />
              </StyledBadge>
            </IconButton>
          </Grid>
        </Grid>
        <Divider variant="middle" />
        <ToggleButtonGroup
          value={content}
          exclusive
          onChange={handleContentChange}
          aria-label="content group"
          size="small"
          className={classes.buttonContainer}
        >
          <ToggleButton
            value="company profile"
            aria-label="company profile"
            className={classes.buttongroup}
          >
            Company profile
          </ToggleButton>
          <ToggleButton
            value="Password"
            aria-label="Password"
            className={classes.buttongroup}
          >
            Password
          </ToggleButton>
          <ToggleButton
            value="Billing and payments"
            aria-label="Billing and payments"
            className={classes.buttongroup}
          >
            Billing &#38; payments
          </ToggleButton>
        </ToggleButtonGroup>
        {content === "company profile" && (
          <div>
            <CompanyProfile />
          </div>
        )}
        {content === "Password" && (
          <div>
            <Password />
          </div>
        )}
        {content === "Billing and payments" && (
          <div>
            <SettingBilling />
          </div>
        )}
      </Box>
    </>
  );
};
export default SettingsPage;
