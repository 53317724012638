import React from "react";

import { Provider } from "react-redux";
import { persistor, store } from "./store/store";
import { ThemeProvider } from "@material-ui/core/styles";
import "./App.css";
import { customTheme } from "./theme/customTheme";
import { CssBaseline } from "@material-ui/core";
import { SnackbarProvider } from "notistack";
// import { RootRouter } from "./RootRouter";
import { PersistGate } from "redux-persist/integration/react";

import AppRouter from "./router";
import { HandleResponseErrors } from "@api/HandleResponseErrors";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "react-tagsinput/react-tagsinput.css";
import { ToastContainer } from "react-toastify";

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
          <ThemeProvider theme={customTheme}>
            <CssBaseline />
            <SnackbarProvider
              maxSnack={2}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              autoHideDuration={3000}
              preventDuplicate
            >
              <HandleResponseErrors />
              <ToastContainer />
              <AppRouter />
            </SnackbarProvider>
          </ThemeProvider>
        </LocalizationProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
